const questions_en = [
  {
    "question": "What is the name of the elegant rooftop structure with a spire that gives the building a light and elevated appearance?",
    "options": ["Belvedere","Polymer","Condottier"],
    "correctOption": 0,
    "points": 1,    
  },
  {
    "question": "2.	In which year did Indira Gandhi arrive in Almaty with a delegation?",
    "options": ["1952 г.","1955 г.","1961 г."],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Choose the surname of the artist who designed unique stained glass windows for the First Terminal of Almaty Airport in 1975.",
    "options": ["B. Timchenko","V. Senchenko","A. Panchenko"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "The first biplane appeared in the sky over Almaty in 1919. What was this aircraft? (It might be better to provide images with captions here.)",
    "options": ["Caproni","Bristol Scout","Conbur"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "How many passenger airlines serve Almaty Airport?",
    "options": ["More than 20","More than 30","More than 50"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Which building in the center of Almaty resembles the First Terminal of Almaty Airport in its exterior appearance, particularly with a belvedere on the roof? (Images with captions might be useful here as well.)",
    "options": ["House of Elder Seydalin","KIMEP Building","Dostyk Hotel"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "What is the name of the architectural element of the First Terminal of Almaty Airport that was used in the Timurid era for constructing mosques, madrasahs, and caravanserais?",
    "options": ["Iwan","Ayran","Buran"],
    "correctOption": 0,
    "points": 1
  },
  {
    "question": "How many flights per year does Almaty Airport handle?",
    "options": ["More than 40,000","More than 50,000","More than 60,000"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "Which famous American politician was met by the First Terminal of Almaty Airport in 1978?",
    "options": ["Senator Joe Biden","Senator Edward Kennedy","President Jimmy Carter"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "What model of aircraft was placed in front of the airport building in the 1970s?",
    "options": ["Il-62","Tu-134","Yak-40"],
    "correctOption": 0,
    "points": 1,
    "image": require('../assets/img/q_10.png')
  },
  {
    "question": "The Day of Civil Aviation Workers of the Republic of Kazakhstan is celebrated on:",
    "options": ["June ","February 1","April 12"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Almaty Airport was the first in the world to accept a commercial flight of a supersonic passenger aircraft in December 1975. Which aircraft was it?",
    "options": ["Concorde","Tu-144","Boeing 2707"],
    "correctOption": 1,
    "points": 1
  }
     
];  


export default questions_en;