import { Trans, useTranslation } from "react-i18next";

function StartScreen({ numQuestions, dispatch }) {
  const { t } = useTranslation();

  return (
    <div className="start">
      <h2><Trans>&nbsp;</Trans> </h2>
      <div className="txt">
          <Trans>{ t("annotation_txt") }</Trans>
      </div>
      <button
        className="btn btn-ui"
        onClick={() => dispatch({ type: "start" })}
      >
        { t("start") }
      </button>
    </div>
  );
}

export default StartScreen;
