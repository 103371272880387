import Footer from "./Footer";
import { useTranslation } from "react-i18next";

function FinishScreen({ points, maxPossiblePoints, highscore, dispatch }) {
  const percentage = (points / maxPossiblePoints) * 100;

  const { t, i18n } = useTranslation();  

  // let emoji;
  // if (percentage === 100) emoji = "🥇";
  // if (percentage >= 80 && percentage < 100) emoji = "🎉";
  // if (percentage >= 50 && percentage < 80) emoji = "🙃";
  // if (percentage >= 0 && percentage < 50) emoji = "🤨";
  // if (percentage === 0) emoji = "🤦‍♂️";

  return (
    <div className="result_container">
      <p className="result">
       { t("you_scored") } - {points} / {maxPossiblePoints} 
      </p>
      <Footer>
        <button
          className="btn btn-ui"
          onClick={() => dispatch({ type: "restart" })}
        >
          { t("goback") }
        </button>
        <button
          className="btn btn-ui"
          onClick={() => dispatch({ type: "restart2" })}
        >
          { t("restart") }
        </button>
      </Footer>

    </div>
  );
}

export default FinishScreen;
