import { useEffect } from "react";

function Timer2({ dispatch, secondsRemaining }) {

  useEffect(
    function () {
      const id = setTimeout(function () {
        dispatch({ type: "restart" });
      }, 10000);

      return () => clearTimeout(id);
    },
    [dispatch]
  );

  return null;
}

export default Timer2;
