import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import {
  defaultLanguage,
  supportedLanguages,
} from "../config/i18n";


export default function LangSelector() {

  const { i18n } = useTranslation();
  

  return (
    <div className="lang-selector-container">
      <div className="lang-selector">

      {supportedLanguages.map((lang) => (
          <button key={lang.code}
          className= {"btn2" +  (i18n.language == lang.code?" active":"") }
          onClick={() => changeLanguage(lang.code)}
          >
          {lang.name}
          </button>
        ))}

      </div>      
    </div>
  );
}
