import Options from "./Options";

function Question({ question, dispatch, answer }) {

  if (question.image!=null) {
    return (
      <>
      <div className="question_container">
        <h4>{question.question}</h4>
        <div className="img-block">
          <img src={question.image}/>
        </div>                  
        <Options question={question} dispatch={dispatch} answer={answer} />
        
      </div>
      <div style={{clear:"both"}}></div>
      </>
    );
  
  }

  return (
    <div className="question_container">
      <h4>{question.question}</h4>
      <Options question={question} dispatch={dispatch} answer={answer} />
    </div>
  );
}

export default Question;
