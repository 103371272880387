import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "./Header";
import Main from "./Main";
import Loader from "./Loader";
import Error from "./Error";
import StartScreen from "./StartScreen";
import Question from "./Question";
import NextButton from "./NextButton";
import Progress from "./Progress";
import FinishScreen from "./FinishScreen";
import Footer from "./Footer";
import Timer from "./Timer";
import LangSelector from "./LangSelector";

import question_bank from "./QuestionBank";

import "../index.css";
import Logo from "./Logo";
import FinishButton from "./FinishButton";
import Timer2 from "./Timer2";

const SECS_PER_QUESTION = 15;

// We need to define the intialState in order to use useReduce Hook.
const initialState = {
  questions: [],
  // 'loading', 'error', 'ready', 'active', 'finished'
  status: "loading",
  index: 0,
  answer: null,
  points: 0,
  highscore: 0,
  secondsRemaining: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "dataReceived":
      return {
        ...state,
        questions: action.payload,
        status: "ready",
      };
    case "dataFailed":
      return {
        ...state,
        status: "error",
      };
    case "start":
      return {
        ...state,
        status: "active",
        secondsRemaining: state.questions.length * SECS_PER_QUESTION,
      };
    case "newAnswer":
      const question = state.questions.at(state.index);

      return {
        ...state,
        answer: action.payload,
        points:
          action.payload === question.correctOption
            ? state.points + question.points
            : state.points,
      };
    case "nextQuestion":
      return { ...state, index: state.index + 1, answer: null };
    case "finish":
      return {
        ...state,
        status: "finished",
        highscore:
          state.points > state.highscore ? state.points : state.highscore,
      };
    case "restart":
      return { ...initialState, questions: state.questions, status: "ready" };

    case "restart2":
      return { ...initialState, questions: state.questions, status: "active", secondsRemaining: state.questions.length * SECS_PER_QUESTION, };
  
    case "tick":
      return {
        ...state,
        secondsRemaining: state.secondsRemaining - 1,
        highscore:
          state.secondsRemaining === 0
            ? state.points > state.highscore
              ? state.points
              : state.highscore
            : state.highscore,
        status: state.secondsRemaining === 0 ? "finished" : state.status,
      };

    default:
      throw new Error("Action unkonwn");
  }
}

export default function App() {

  const [
    { questions, status, index, answer, points, highscore, secondsRemaining },
    dispatch,
  ] = useReducer(reducer, initialState);



  const [language, setLanguage] = useState([]);
  const { i18n } = useTranslation();  

  useEffect(() => {

    dispatch({
      type: "dataReceived",
      payload: question_bank[i18n.language],
    });

    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;    

    // document.title = i18n.t("document_title");
  }, [i18n, i18n.language]);

  function changeLang(lang) {
    i18n.changeLanguage(lang)
    setLanguage(lang);
  }
  
  const numQuestions = questions?.length;
  const maxPossiblePoints = questions?.reduce(
    (prev, cur) => prev + cur.points,
    0
  );

  return (
    <>
      <Logo status={status} />

      <div className="wrapper">
      
      <div className="app">

          <Main>
            {status === "loading" && <Loader />}
            {status === "error" && <Error />}
            {status === "ready" && (
              <div className="start-wrapper">
                <StartScreen numQuestions={numQuestions} dispatch={dispatch} />
                <LangSelector />
              </div>
            )}{" "}
            {status === "active" && (
              <>
                <Question
                  question={questions[index]}
                  dispatch={dispatch}
                  answer={answer}
                />
                <Footer>
                  {/* <Timer
                    dispatch={dispatch}
                    secondsRemaining={secondsRemaining}
                  /> */}
                  <Timer
                    dispatch={dispatch}
                    secondsRemaining={secondsRemaining}
                  />
                  <FinishButton
                    dispatch={dispatch}
                  />
                  <NextButton
                    dispatch={dispatch}
                    answer={answer}
                    numQuestions={numQuestions}
                    index={index}
                  />                  
                </Footer>
              </>
            )}
            {status === "finished" && (
              <>
               <Timer2
                    dispatch={dispatch}
                    secondsRemaining={secondsRemaining}
                  />
              <FinishScreen
                points={points}
                maxPossiblePoints={maxPossiblePoints}
                highscore={highscore}
                dispatch={dispatch}
              />

              </>
            )}
          </Main>
        
      </div>
    </div>
    </>
    
  );
}
