import questions_en from "./QuestionBank_en";
import questions_kz from "./QuestionBank_kz";
import questions_ru from "./QuestionBank_ru";

const question_bank = {
  "en": questions_en,
  "kz": questions_kz,
  "ru": questions_ru
};  


export default question_bank;