import { useTranslation } from "react-i18next";

function NextButton({ dispatch, answer, index, numQuestions }) {
  const { t } = useTranslation();

  if (answer === null) 
    return (
      <button
        className="btn btn-ui" disabled
        onClick={() => dispatch({ type: "nextQuestion" })}
      >
        { t("next") }
      </button>      
  );

  if (index < numQuestions - 1)
    return (
        <button
          className="btn btn-ui"
          onClick={() => dispatch({ type: "nextQuestion" })}
        >
          { t("next") }
        </button>      
    );

  if (index === numQuestions - 1)
   return null;
}

export default NextButton;
