const questions_kz = [
  {
    "question": "Ғимаратқа жеңілдік пен биіктік беретін төбесі (сүмбісі) бар ғимараттың төбесіндегі керемет қондырма қалай аталады?",
    "options": ["Белведер","Полимер","Кондотиер"],
    "correctOption": 0,
    "points": 1
  },
  {
    "question": "Индира Ганди делегациямен Алматыға қай жылы ұшып келді?",
    "options": ["1952 ж","1955 ж","1961 ж"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "1975 жылы әуежайдың бірінші терминалының ғимараты үшін қайталанбас витраждар жасаған суретшінің тегін таңдаңыз",
    "options": ["Б. Тимченко","В. Сенченко","А. Панченко"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Алғашқы ұшақ-биплан 1919 жылы Алматы аспанында пайда болды. Бұл қандай ұшақ болды?",
    "options": ["Caproni","Bristol Scout","Conbur"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "Алматы әуежайына қанша жолаушы авиакомпаниясы қызмет көрсетеді?",
    "options": ["20-дан астам","30-дан астам","50-ден астам"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Алматының орталығындағы қандай ғимарат өзінің сыртқы келбеті бойынша әуежайдың бірінші терминалына ұқсайды - ең алдымен төбесінде бельведер болғандықтан",
    "options": ["Ақсақал Сейдалин үйі","КИМЭП ғимараты","Достық Қонақ Үйі"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Тимуридтер дәуірінде мешіттер, медреселер мен керуен сарайларын салуда қолданылған әуежайдың бірінші терминалы ғимаратының сәулеттік элементі қалай аталады?",
    "options": ["Айван","Айран","Буран"],
    "correctOption": 0,
    "points": 1
  },
  {
    "question": "Алматы әуежайы жылына қанша рейс қабылдайды және жөнелтеді",
    "options": ["40 мыңнан астам","50 мыңнан астам","60 мыңнан астам"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "1978 жылы Алматының алғашқы әуежай терминалы қандай танымал американдық саясаткерді қарсы алды?",
    "options": ["Сенатор Джо Байден","Сенатор Эдвард Кеннеди","Президент Джимми Картер"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "70-ші жылдары әуежай ғимаратының алдына қандай ұшақ моделі орнатылды?",
    "options": ["Ил-62","Ту-134","Як-40"],
    "correctOption": 0,
    "points": 1,
    "image": require('../assets/img/q_10.png')
  },
  {
    "question": "Қазақстан Республикасы Азаматтық авиация қызметкерлерінің күні:",
    "options": ["25 маусым","1 ақпан","12 сәуір"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Алматы әуежайы әлемде бірінші болып 1975 жылы желтоқсанда асқындыбыстық жылдамдықпен ұшқан азаматтық авиалайнердің жұмыс рейсін қабылдады. Мұнда қандай ұшақ туралы айтылып тұр?",
    "options": ["Конкорд","Ту-144","Boeing 2707"],
    "correctOption": 1,
    "points": 1
  }
     
];  


export default questions_kz;