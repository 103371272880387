const questions_ru = [
  {
    "question": "Как называется изящная надстройка на крыше здания со шпилем, которая придает зданию легкость и возвышенность?",
    "options": ["Бельведер","Полимер","Кондотьер"],
    "correctOption": 0,
    "points": 1
  },
  {
    "question": "В каком году в Алматы прилетал с делегацией Индира Ганди?",
    "options": ["1952 г.","1955 г.","1961 г."],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Выберите фамилию художника, который в 1975 году разработал для здания Первого терминала алматинского аэропорта уникальные витражи?",
    "options": ["Б. Тимченко", "В. Сенченко", "А. Панченко"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Первый самолет-биплан появился в небе над Алматы в 1919 году. Что это был за самолет?",
    "options": ["Caproni","Bristol Scout","Conbur"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "Сколько пассажирских авиакомпаний обслуживает алматинский аэропорт?",
    "options": ["Более 20","Более 30","Более 50"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Какое здание в центре Алматы похоже по своему внешнему облику на здание Первого терминала алматинского аэропорта - в первую очередь наличием на кровле бельведера?",
    "options": ["Дом аксакала Сейдалина","Здание КИМЭП","Гостиница Достык"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Как называется архитектурный элемент здания Первого терминала алматинского аэропорта, который использовался в эпоху Тимуридов при строительстве мечетей, медресе и караван-сараев?",
    "options": ["Айван","Айран","Буран"],
    "correctOption": 0,
    "points": 1
  },
  {
    "question": "Какое количество рейсов в год принимает и отправляет алматинский аэропорт?",
    "options": ["Более 40 тысяч","Более 50 тысяч","Более 60 тысяч"],
    "correctOption": 2,
    "points": 1
  },
  {
    "question": "Какого известного американского политика встречал алматинский Первый терминал алматинского аэропорта в 1978 году?",
    "options": ["Сенатор Джо Байден","Сенатор Эдвард Кеннеди","Президент Джимми Картер"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Какая модель самолета была установлена перед зданием аэропорта в 70-е годы?",
    "options": ["Ил-62","Ту-134","Як-40"],
    "correctOption": 0,
    "points": 1,
    "image": require('../assets/img/q_10.png')
  },
  {
    "question": "День работников гражданской авиации Республики Казахстан отмечают:",
    "options": ["25 июня","1 февраля","12 апреля"],
    "correctOption": 1,
    "points": 1
  },
  {
    "question": "Аэропорт Алматы первым в мире принял в декабре 1975 года рабочий рейс гражданского авиалайнера, полет которого проходил со сверхзвуковой скоростью. О каком самолете идет речь?",
    "options": ["Конкорд","Ту-144","Boeing 2707"],
    "correctOption": 1,
    "points": 1
  }
     
];  


export default questions_ru;