import { useTranslation } from "react-i18next";

function FinishButton({ dispatch }) {
  const { t } = useTranslation();
    return (
      <button
        className="btn btn-ui"
        onClick={() => dispatch({ type: "finish" })}
      >
        { t("finish") }
      </button>
    );

  }

export default FinishButton;
