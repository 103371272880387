export default function Logo({status}) {

    if (status === "ready" ) {
        document.body.classList.add('start');
      } else {
        document.body.classList.remove('start');
        return (
            <div className="logo_side-wrapper">
              <div className="logo_side">
                  <img src={ require('../assets/img/logo_side.png') }/>
              </div>
            </div>
          );        
      }
    return null;

  }
  